export const logoutUser = (redirect = null) => {
    const localePath = useLocalePath();

    redirect = localePath(redirect);

    const localizedLogout = localePath('/auth/signout');

    if (redirect) {
        navigateTo(`${localizedLogout}?redirect=${redirect}`);
        return;
    }

    navigateTo(localizedLogout);
};
